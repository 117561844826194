/*
This component is a wrapper for the Personal Guarantor form. 
It is a card component that contains the form and buttons for submitting or skipping the form. 
The card has a title that explains what a personal guarantor is and what their role is in the company. 
The wrapper further contains a form and a footer component with buttons for submitting or skipping the form.
*/

import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { uniqueId, get } from 'lodash';
import * as yup from 'yup';
import { Card, Typography } from '@vartanainc/design-system';
import { useReactiveVar } from '@apollo/client';
import { toast } from 'react-toastify';
import SvgIcon from '../../components/SvgIcon/SvgIcon';

import { sessionVar } from '../../graphql/cache';
import { ReactComponent as AddAnotherIcon } from '../../assets/circle_cross.svg';
import { PersonalGuarantorForm } from './PersonalGuarantorForm';
import { ADD_ANOTHER_GUARANTOR } from '../../constants/common.constants';
import FormCheckbox from '../../components/FormCheckboxV1';
import { PersonalGuarantorFormFooter } from './PersonalGuarantorFormFooter';
import { TEXT_CONSTANTS } from '../../constants/ui/text.constants';
import { Guarantor, GuarantorWithoutId, PgFormValues } from './PersonalGuarantorTypes';
import CustomNotification from '../../components/CustomNotification';
import { formSchema, ssnSchema, sinSchema } from '../../formikSchema/commonSchema';
import { isWidget } from '../../utils/helpers';

const MAX_GUARANTORS = 5;
const MIN_GUARANTORS = 1;

const DEFAULT_GUARANTOR = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  title: '',
  ssn: '',
  sin: '',
  dob: '',
  street: '',
  city: '',
  zip: '',
  state: '',
  percentageOfOwnership: '',
  primary: false,
};

const INITIAL_FORM_VALUES = {
  personalGuarantors: [
    {
      ...DEFAULT_GUARANTOR,
      id: uniqueId(),
      primary: true,
    },
  ],
  pgConsent: false,
};
interface PersonalGuarantorProps {
  handleBackButtonClick: () => void;
  handleSkipButtonClick: () => Promise<void>;
  setGuarantorsInForm: (
    personalGuarantors: GuarantorWithoutId[]
  ) => Promise<{ [key: string]: string[] }>;
  showSinField: boolean;
  selectedCountry: string;
  resetPGAddressSwitch: boolean;
}

export default function PersonalGuarantor({
  handleBackButtonClick,
  handleSkipButtonClick,
  setGuarantorsInForm,
  showSinField,
  selectedCountry,
  resetPGAddressSwitch,
}: PersonalGuarantorProps): ReactElement {
  const pgFormSchema = formSchema.concat(showSinField ? sinSchema : ssnSchema);

  const showPersonalGuarantorFieldError = (errorMessage: string): void => {
    toast.error(({ toastProps }) => (
      <CustomNotification
        type={toastProps.type || 'error'}
        title="Information invalid"
        message={errorMessage}
      />
    ));
  };

  // Function to handle submission of the Personal Guarantor form
  async function submitPersonalGuarantorForm(
    values: PgFormValues,
    formikHelpers: FormikHelpers<PgFormValues>
  ): Promise<void> {
    // Process guarantor data by removing fields that should not be sent if empty
    const guarantorValues = values.personalGuarantors.map(({ id, phone, ...rest }) => {
      return phone ? { ...rest, phone } : rest;
    });

    // Submit the processed guarantor data to the parent form and retrieve any validation errors returned by the parent
    const submissionErrors = await setGuarantorsInForm(guarantorValues);

    // If there are any errors, set them in Formik's state and display an error notification for the first issue found
    if (Object.keys(submissionErrors).length) {
      formikHelpers.setErrors(submissionErrors);
      showPersonalGuarantorFieldError(
        `${Object.keys(submissionErrors)[0]} ${Object.values(submissionErrors)[0]}` // Display the first error field and its corresponding message in the error notification
      );
    }
  }

  const formikBag = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      personalGuarantors: yup
        .array()
        .of(pgFormSchema)
        .min(1, 'At least one guarantor is required'),
      pgConsent: yup.boolean().oneOf([true], 'You must accept the terms').required(),
    }),
    onSubmit: (values) => submitPersonalGuarantorForm(values, formikBag),
  });
  const sessionData = useReactiveVar(sessionVar);

  const multipleCountriesEnabled = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled',
    false
  );
  const enabledCountries = get(
    sessionData,
    'session.user.company.product.enabledCountries',
    []
  );

  const createGuarantor = (): Guarantor => ({ ...DEFAULT_GUARANTOR, id: uniqueId() });

  const addAnotherGuarantor = useCallback((): void => {
    formikBag.setFieldValue('personalGuarantors', [
      ...formikBag.values.personalGuarantors,
      createGuarantor(),
    ]);
  }, [formikBag]);

  useEffect(() => {
    // this useEffect resets the address form values when the resetPGAddressSwitch is toggled
    const prevGuarantors = formikBag.values.personalGuarantors;
    const guarantorsWithoutAddress = prevGuarantors.map((guarantor) => {
      return { ...guarantor, street: '', city: '', state: '', zip: '', sin: '', ssn: '' };
    });
    if (selectedCountry) {
      formikBag
        .setValues({
          personalGuarantors: guarantorsWithoutAddress,
          pgConsent: false,
        })
        .then(() => {
          formikBag.validateForm();
        });

      // Reset the touched state of the address fields for all guarantors
      formikBag.setTouched({
        personalGuarantors: guarantorsWithoutAddress.map(() => ({
          street: false,
          city: false,
          state: false,
          zip: false,
          sin: false,
          ssn: false,
        })),
        pgConsent: false,
      });

      // Reset the touched state of the address fields for all guarantors
      formikBag.setTouched({
        personalGuarantors: guarantorsWithoutAddress.map(() => ({
          street: false,
          city: false,
          state: false,
          zip: false,
          sin: false,
          ssn: false,
        })),
        pgConsent: false,
      });
    }
    // we should run this effect only when the resetPGAddressSwitch is toggled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPGAddressSwitch]);

  const numberOfGuarantors = useMemo(
    () => formikBag.values.personalGuarantors.length,
    [formikBag.values.personalGuarantors]
  );

  // Memoized check to verify if all guarantor forms are valid.
  // Returns true if there are no validation errors for any guarantor; otherwise, false.
  // Recalculates only when guarantor values or errors change.
  const areAllGuarantorsValid = useMemo(() => {
    return formikBag.values.personalGuarantors.every((_, index) => {
      const guarantorErrors = formikBag.errors?.personalGuarantors?.[index] || {};
      const isValid = !Object.keys(guarantorErrors).length;
      return isValid;
    });
  }, [formikBag.errors, formikBag.values.personalGuarantors]);

  const renderConsentCheckbox = (): ReactElement => (
    <>
      <div className="consent-container flex flex-row gap-2 mt-4">
        <FormCheckbox name="pgConsent" />
        <Typography variant="paragraph14" color="color-gray-140">
          <label htmlFor="pgConsent">{TEXT_CONSTANTS.PG_ACKNOWLEDGEMENT}</label>
        </Typography>
      </div>
      <PersonalGuarantorFormFooter
        handleBackButtonClick={handleBackButtonClick}
        handleSkipButtonClick={handleSkipButtonClick}
      />
    </>
  );

  const addGuarantorButtonDisabled = useMemo(() => {
    return numberOfGuarantors === MAX_GUARANTORS || !areAllGuarantorsValid;
  }, [numberOfGuarantors, areAllGuarantorsValid]);
  const addGuarantorButtonClass = useMemo(() => {
    return `${
      addGuarantorButtonDisabled ? 'disabled-btn' : ''
    } flex items-center gap-1 w-fit mt-2 card-subtitle-bold text-vartana-blue-120 disabled:text-vartana-gray-40`;
  }, [addGuarantorButtonDisabled]);

  return (
    <>
      <FormikProvider value={formikBag}>
        <Card
          variant="fullWidth"
          title={
            !isWidget && (
              <div className="grid gap-2.5">
                <div className="flex gap-2 items-center">
                  <SvgIcon
                    name="background_replace"
                    width="2rem"
                    height="2rem"
                    fill="color-blue-120"
                  />
                  <Typography variant="heading20" bold color="color-black-100">
                    Personal guarantor (optional)
                  </Typography>
                </div>
                <Typography variant="paragraph14" color="color-black-100">
                  An individual with ownership or executive responsibilities in the
                  company who can guarantee the payment agreement with personal credit in
                  case the business defaults.
                </Typography>
              </div>
            )
          }
          content={(
            <div className="pg-form-container">
              {formikBag.values.personalGuarantors.map((guarantor, index) => (
                <PersonalGuarantorForm
                  key={guarantor.id}
                  guarantorIndex={index}
                  showGuarantorNumber={
                    numberOfGuarantors > MIN_GUARANTORS || (isWidget && index === 0)
                  }
                  showRemoveGuarantorButton={numberOfGuarantors > MIN_GUARANTORS}
                  showSinField={showSinField}
                  multipleCountriesEnabled={multipleCountriesEnabled}
                  enabledCountries={enabledCountries}
                  selectedCountry={selectedCountry}
                  resetAddressSwitch={resetPGAddressSwitch}
                />
              ))}

              <div className="flex flex-col mt-4">
                <button
                  type="button"
                  disabled={addGuarantorButtonDisabled}
                  className={addGuarantorButtonClass}
                  onClick={addAnotherGuarantor}
                >
                  <AddAnotherIcon className="w-8 h-8" />
                  <Typography
                    variant="heading16"
                    bold
                    color={
                      addGuarantorButtonDisabled ? 'color-gray-100' : 'color-blue-120'
                    }
                  >
                    {ADD_ANOTHER_GUARANTOR}
                  </Typography>
                </button>
              </div>

              {!isWidget && (
                <>
                  <hr className="h-px bg-vartana-gray-40-v3 my-6" />
                  {renderConsentCheckbox()}
                </>
              )}
            </div>
          )}
        />
        {isWidget && renderConsentCheckbox()}
      </FormikProvider>
    </>
  );
}
